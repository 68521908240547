import { setNotification } from 'actions/notification';
import Button from 'components/ui/button/Button';
import CardCTA from 'components/ui/cards/cardCTA/CardCTA';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useSurvey } from '../../hooks/useSurvey';
import { useContext } from 'react';
import { UserContext } from 'context/userContext';
import ButtonDropdown from 'components/ui/buttonDropdown/ButtonDropdown';
import { FilterDatesContext } from 'context/filterDatesContext';

export const SurveyBanner = ({
  title,
  subtitle,
  stakeholder,
  Cta1
}: {
  title: string;
  subtitle: string;
  stakeholder: 'suppliers' | 'b2b_clients' | 'final_users' | 'own_workforce' | 'investors';
  Cta1: JSX.Element;
}) => {
  const { t: t } = useTranslation('', { keyPrefix: 'doubleMateriality.stakeholders.surveyBanner' });
  const { t: t_notify } = useTranslation('', { keyPrefix: 'notification' });

  const dispatch = useDispatch();

  const user = useContext(UserContext);
  const { selectedYear } = useContext(FilterDatesContext);

  const {
    data: surveyData,
    isLoading,
    error
  } = useSurvey({
    year: selectedYear,
    stakeholder,
    organizationId: user?.selectedOrganization ?? ''
  });

  const dropdownOptions = [
    {
      id: 'es',
      name: t('copySpanish'),
      onClick: () => {
        navigator.clipboard.writeText(surveyData?.survey_link_es ?? '');
        dispatch(setNotification(t_notify('copyOnBoard')));
      }
    },
    {
      id: 'en',
      name: t('copyEnglish'),
      onClick: () => {
        navigator.clipboard.writeText(surveyData?.survey_link_en ?? '');
        dispatch(setNotification(t_notify('copyOnBoard')));
      }
    }
  ];

  return (
    <CardCTA>
      <CardCTA.Header>
        <span className='font-14 weight-600'>{title}</span>
        <span className='font-12'>{subtitle}</span>
      </CardCTA.Header>
      <CardCTA.Buttons>
        {Cta1}
        <ButtonDropdown
          style={{
            width: 'fit-content'
          }}
          dropdownStyle={{
            top: '28px',
            right: '50%',
            transform: 'translateX(50%)'
          }}
          position='bottom'
          button={
            <Button
              lookAndFeel={'primary'}
              loading={isLoading}
              disabled={Boolean(error)}
              text={t('copyQuestionnaireLink')}
              size='small'
            />
          }
          options={dropdownOptions}></ButtonDropdown>
      </CardCTA.Buttons>
    </CardCTA>
  );
};
