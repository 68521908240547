import { useInfiniteQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { setNotification } from 'actions/notification';
import { adaptInvestorFrontToBack, adaptInvestorsBackToFront } from 'adapters/investors';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import {
  createInvestor,
  deleteInvestor,
  fetchDMInvestors,
  updateInvestor
} from 'services/api/sotOrganizationData';

export const useObtainInvestors = ({ year }: { year: string }) => {
  const { data, fetchNextPage, isLoading } = useInfiniteQuery({
    queryKey: ['investors', year],
    queryFn: fetchDMInvestors,
    initialPageParam: 1,
    select: adaptInvestorsBackToFront,
    getNextPageParam: (lastPage) => {
      if (lastPage.page * lastPage.size >= lastPage.total) {
        return undefined;
      }

      return lastPage.page + 1;
    }
  });

  return { data, fetchNextPage, isLoading };
};

export const useCreateOrUpdateInvestor = ({ year }: { year: string }) => {
  const { t } = useTranslation('', { keyPrefix: 'notification' });
  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  const { mutate } = useMutation({
    mutationFn: (investor: DMInvestor) => {
      const backendInvestor = adaptInvestorFrontToBack(investor);
      return investor.id
        ? updateInvestor(investor.id, backendInvestor, year)
        : createInvestor(backendInvestor, year);
    },
    onSuccess: async () => {
      queryClient.invalidateQueries({ queryKey: ['investors'] });
      dispatch(setNotification(t('edit')));
    },
    onError: () => {
      dispatch(setNotification(t('error')));
    }
  });

  return { createOrUpdateInvestor: mutate };
};

export const useDeleteInvestor = () => {
  const { t } = useTranslation('', { keyPrefix: 'notification' });
  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  const { mutate } = useMutation({
    mutationFn: (investorId: string) => {
      return deleteInvestor(investorId);
    },
    onSuccess: async () => {
      queryClient.invalidateQueries({ queryKey: ['investors'] });
      dispatch(setNotification(t('delete')));
    },
    onError: () => {
      dispatch(setNotification(t('error')));
    }
  });

  return { deleteInvestor: mutate };
};
