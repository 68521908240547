import NuvoImporter from 'components/ui/nuvoImporter/NuvoImporter';
import { countriesNuvo } from 'constants/countriesNuvo';
import useErrors from 'customHooks/useErrors';
import useNuvoButton from 'customHooks/useNuvoButton';
import useNuvoFileName from 'customHooks/useNuvoFileName';
import useSelectedOrganization from 'customHooks/useSelectedOrganization';
import { ColumnAPI, LanguageType, RejectSubmitResult } from 'nuvo-react';
import { useTranslation } from 'react-i18next';
import { postNuvoAnalytics } from 'services/api/nuvoAnalytics';
import { getPresignedUrlClients } from 'services/api/sotOrganizationData';
import { basicNuvoStyle } from 'styles/nuvo';
import { transformNuvoResultsIntoCsv } from 'utils/nuvo';
import { sessionStorageSetItem } from 'utils/sessionStorage';
import { uploadFilePresignedUrl } from 'services/api/aws';
import { useContext } from 'react';
import { FilterDatesContext } from 'context/filterDatesContext';
const CATEGORY = 'clients';
const LOOK_AND_FEEL = 'secondary';

export const InputNuvoClients = () => {
  const { t, i18n } = useTranslation();
  const selectedOrganization = useSelectedOrganization();

  const { startDate } = useContext(FilterDatesContext);
  const year = startDate.split('/')[2];

  const ERRORS = useErrors();

  const { fileName, handleGetFileName, handleExit } = useNuvoFileName({
    lookAndFeel: LOOK_AND_FEEL
  });
  const onClick = () => {
    handleGetFileName();
    sessionStorageSetItem('isNuvoOpen', true);
  };
  useNuvoButton({ onClick });

  const nuvoError = new RejectSubmitResult(
    ERRORS.NUVO.GENERAL_ERROR_TITLE,
    ERRORS.NUVO.GENERAL_ERROR_MESSAGE
  );

  const nuvoErrorFounded = new RejectSubmitResult(
    ERRORS.NUVO.ERRORS_FOUNDED_TITLE,
    ERRORS.NUVO.ERRORS_FOUNDED_MESSAGE
  );

  const columns: ColumnAPI[] = [
    {
      key: 'legal_name',
      label: t('doubleMateriality.context.clients.name'),
      columnType: 'string',
      validations: [
        {
          validate: 'required'
        }
      ]
    },
    {
      key: 'country',
      label: t('doubleMateriality.context.clients.country'),
      columnType: 'category',
      dropdownOptions: countriesNuvo,
      validations: [
        {
          validate: 'required'
        }
      ]
    },
    {
      key: 'relevance',
      label: t('doubleMateriality.context.clients.importance'),
      columnType: 'category',
      dropdownOptions: [
        {
          label: t('doubleMateriality.context.high'),
          value: 1,
          type: 'int'
        },
        {
          label: t('doubleMateriality.context.low'),
          value: 0,
          type: 'int'
        }
      ],
      validations: [
        {
          validate: 'required'
        }
      ]
    }
  ];

  return (
    <NuvoImporter
      lookAndFeel={LOOK_AND_FEEL}
      btnI18nKey='doubleMateriality.context.clients.uploadSales'
      settings={{
        language: i18n.resolvedLanguage as LanguageType,
        style: basicNuvoStyle,
        automaticHeaderDetection: true,
        maxEntries: 700_000,
        identifier: 'clients_template_Dcycle',
        columns
      }}
      onCancel={handleExit}
      onResults={async (results, errors, complete) => {
        if (results.length <= 0 || !selectedOrganization?.id) return complete(nuvoError);

        // cannot submit file with errors
        if (errors.length > 0) return complete(nuvoErrorFounded);

        const finalFileName = `${fileName || CATEGORY}.csv`;

        // get presinged url
        const data = await getPresignedUrlClients(finalFileName, year);

        // error uploading file
        if (data?.response?.status >= 400) return complete(nuvoError);

        const measurementCampaignId = data?.measurement_campaign_id;

        // transform results into csv string and add measurement_campaign_id
        const content = transformNuvoResultsIntoCsv(
          results.map((result) => ({
            ...result,
            measurement_campaign_id: measurementCampaignId
          }))
        );

        // transform content to File
        const file = new File([content], finalFileName, { type: 'text/csv' });

        // upload file to presigned url
        const response = await uploadFilePresignedUrl(file, data?.upload_url);

        // error uploading file
        if (!response) return complete(nuvoError);

        // analytics
        void postNuvoAnalytics({
          numberOfRows: results.length,
          fileName: finalFileName,
          category: CATEGORY
        });

        complete();
      }}
    />
  );
};
