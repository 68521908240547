import InputWrapper from 'components/ui/formComponents2/inputUtils/inputWrapper/InputWrapper';
import Select from 'components/ui/formComponents2/select/Select';
import { PREMIUM_ROUTES, ROUTES } from 'constants/routes';
import useSelectedOrganization from 'customHooks/useSelectedOrganization';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

type Props = {
  show: boolean;
  method: SelectOptionFormat;
  setMethod: (value: SelectOptionFormat) => void;
};

const MethodFilter = ({ show, method, setMethod }: Props) => {
  const { t } = useTranslation('translation', { keyPrefix: 'dashboard' });
  const navigate = useNavigate();
  const organization = useSelectedOrganization();
  const subscriptionPlan = organization?.subscription_plan;

  const options: SelectOptionFormat[] = [
    {
      id: 'consolidated',
      name: t('consolidated')
    },
    {
      id: 'investment',
      name: t('investment')
    },
    {
      id: 'grouping',
      name: t('grouping')
    }
  ];

  const onChange = (value: SelectOptionFormat<string>) => {
    setMethod(value);

    let route = '';
    switch (value.id) {
      case 'consolidated':
        route = ROUTES.IMPROVE_DASHBOARD;
        break;
      case 'investment':
        route = ROUTES.IMPROVE_DASHBOARD_INVESTMENT;
        break;
      case 'grouping':
        route = ROUTES.IMPROVE_DASHBOARD_GROUPING;
        break;
      default:
        break;
    }
    const premium = PREMIUM_ROUTES.find((elem) => elem === route);

    if (!route) {
      return;
    }

    if (!premium || subscriptionPlan !== 'free_plan') {
      navigate(route, { replace: false });
    }
  };

  useEffect(() => {
    if (show) {
      if (window.location.pathname === ROUTES.IMPROVE_DASHBOARD_GROUPING) {
        setMethod(options[2]);
      } else {
        setMethod(options[0]);
      }
    }
  }, [show]);

  if (!show) {
    return null;
  }

  return (
    <InputWrapper label={t('methodFilterLabel')} iconV2='filter'>
      <Select options={options} onChange={onChange} value={method} sort={false} />
    </InputWrapper>
  );
};

export default MethodFilter;
