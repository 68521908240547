import Button from 'components/ui/button/Button';
import { FilterDatesContext } from 'context/filterDatesContext';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useColumns } from './hooks/useColumns';
import { useObtainFinalUsers } from './hooks/useFinalUsers';
import InfiniteList from 'components/ui/infiniteList/InfiniteListV2';
import InputWrapper from 'components/ui/formComponents2/inputUtils/inputWrapper/InputWrapper';
import InputText from 'components/ui/formComponents2/inputText/InputText';
import Modal from 'components/ui/modal/Modal';
import { ModalCreateOrEditFinalUser } from './ModalCreateOrEditFinalUser';
import { ModalDeleteFinalUser } from './ModalDeleteFinalUser';
import { SurveyBanner } from '../SurveyBanner';

export const DMFinalUsers = () => {
  const { t: t_esg } = useTranslation('', { keyPrefix: 'esgMetrics' });
  const { t: t_dm } = useTranslation('', { keyPrefix: 'doubleMateriality' });

  const { startDate } = useContext(FilterDatesContext);
  const year = startDate.split('/')[2];

  const [finalUserToEdit, setFinalUserToEdit] = useState<DMFinalUser>();
  const [finalUserToDelete, setFinalUserToDelete] = useState<DMFinalUser>();
  const [searchValue, setSearchValue] = useState('');

  const columns = useColumns({
    setFinalUserToEdit,
    setFinalUserToDelete
  });
  const { data, fetchNextPage, isLoading } = useObtainFinalUsers({
    year
  });
  const { finalUsers, total } = data ?? {};

  const filteredFinalUsers = finalUsers?.filter((finalUser) =>
    finalUser.name.toLowerCase().includes(searchValue.toLowerCase())
  );

  return (
    <div
      style={{
        display: 'grid',
        gridTemplateRows: 'auto 55vh'
      }}>
      <SurveyBanner
        title={t_dm('finalUserTitle')}
        subtitle={t_dm('finalUserSubtitle')}
        stakeholder='final_users'
        Cta1={
          <Button
            lookAndFeel={'secondary'}
            text={t_dm('context.finalUsers.addFinalUsers')}
            size='small'
            onClick={() =>
              setFinalUserToEdit({
                name: '',
                country: '',
                highImportance: false
              })
            }
          />
        }
      />
      <InfiniteList
        fetchData={fetchNextPage}
        data={filteredFinalUsers ?? []}
        columns={columns}
        loading={isLoading}
        firstLoading={isLoading}
        total={total}
        header={
          <div className='flex justify-between items-center w-full'>
            <div className='flex items-center gap-2'>
              <InputWrapper icon={'/images/icons/search.svg'}>
                <InputText
                  placeholder={t_esg('filters.search')}
                  value={searchValue}
                  onChange={(e) => setSearchValue(e.target.value)}
                />
              </InputWrapper>
            </div>
          </div>
        }
      />
      <Modal.WithPortal show={!!finalUserToEdit} onClose={() => setFinalUserToEdit(undefined)}>
        <ModalCreateOrEditFinalUser
          finalUserData={finalUserToEdit}
          closeModal={() => setFinalUserToEdit(undefined)}
        />
      </Modal.WithPortal>
      <Modal.WithPortal show={!!finalUserToDelete} onClose={() => setFinalUserToDelete(undefined)}>
        <ModalDeleteFinalUser
          finalUserData={finalUserToDelete}
          closeModal={() => setFinalUserToDelete(undefined)}
        />
      </Modal.WithPortal>
    </div>
  );
};
