import Button from 'components/ui/button/Button';
import InputText from 'components/ui/formComponents2/inputText/InputText';
import InputWrapper from 'components/ui/formComponents2/inputUtils/inputWrapper/InputWrapper';
import InfiniteList from 'components/ui/infiniteList/InfiniteListV2';
import { FilterDatesContext } from 'context/filterDatesContext';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useColumns } from './hooks/useColumns';
import { useObtainOwnWorkers } from './hooks/useOwnWorkers';
import Modal from 'components/ui/modal/Modal';
import { ModalCreateOrEditOwnWorker } from './ModalCreateOrEditOwnWorker';
import { ModalDeleteOwnWorker } from './ModalDeleteOwnWorker';
import { SurveyBanner } from '../SurveyBanner';

export const DMOwnWorkers = () => {
  const { t: t_esg } = useTranslation('', { keyPrefix: 'esgMetrics' });
  const { t: t_dm } = useTranslation('', { keyPrefix: 'doubleMateriality' });

  const { startDate } = useContext(FilterDatesContext);
  const year = startDate.split('/')[2];

  const [ownWorkerToEdit, setOwnWorkerToEdit] = useState<DMOwnWorker>();
  const [ownWorkerToDelete, setOwnWorkerToDelete] = useState<DMOwnWorker>();
  const [searchValue, setSearchValue] = useState('');

  const columns = useColumns({
    setOwnWorkerToEdit,
    setOwnWorkerToDelete
  });
  const { data, fetchNextPage, isLoading } = useObtainOwnWorkers({
    year
  });
  const { ownWorkers, total } = data ?? {};

  const filteredOwnWorkers = ownWorkers?.filter((ownWorker) =>
    ownWorker.name.toLowerCase().includes(searchValue.toLowerCase())
  );

  return (
    <div
      style={{
        display: 'grid',
        gridTemplateRows: 'auto 55vh'
      }}>
      <SurveyBanner
        title={t_dm('ownWorkersTitle')}
        subtitle={t_dm('ownWorkersSubtitle')}
        stakeholder='own_workforce'
        Cta1={
          <Button
            lookAndFeel={'secondary'}
            text={t_dm('context.ownWorkers.addOwnWorkers')}
            size='small'
            onClick={() =>
              setOwnWorkerToEdit({
                name: '',
                description: '',
                country: '',
                highImportance: false
              })
            }
          />
        }
      />
      <InfiniteList
        fetchData={fetchNextPage}
        data={filteredOwnWorkers ?? []}
        columns={columns}
        loading={isLoading}
        firstLoading={isLoading}
        total={total}
        header={
          <div className='flex justify-between items-center w-full'>
            <div className='flex items-center gap-2'>
              <InputWrapper icon={'/images/icons/search.svg'}>
                <InputText
                  placeholder={t_esg('filters.search')}
                  value={searchValue}
                  onChange={(e) => setSearchValue(e.target.value)}
                />
              </InputWrapper>
            </div>
          </div>
        }
      />
      <Modal.WithPortal
        show={ownWorkerToEdit !== undefined}
        onClose={() => setOwnWorkerToEdit(undefined)}>
        <ModalCreateOrEditOwnWorker
          ownWorkerData={ownWorkerToEdit}
          closeModal={() => setOwnWorkerToEdit(undefined)}
        />
      </Modal.WithPortal>
      <Modal.WithPortal
        show={ownWorkerToDelete !== undefined}
        onClose={() => setOwnWorkerToDelete(undefined)}>
        <ModalDeleteOwnWorker
          ownWorkerData={ownWorkerToDelete}
          closeModal={() => setOwnWorkerToDelete(undefined)}
        />
      </Modal.WithPortal>
    </div>
  );
};
