import { useContext, useEffect, useState } from 'react';
import { FilterDatesContext } from '../../../context/filterDatesContext';

import useSelectedOrganization from '../../../customHooks/useSelectedOrganization';
import { checkFreePlan } from '../../../utils/checkFreePlan';
import { convertStringToDate } from '../../../utils/convertDates';
import LoaderCard from '../../ui/loaders/loaderCard/LoaderCard';
import CompanyEnvironmentImpact from '../dashboardCommon/companyEnviromentImpact/CompanyEnvironmentImpact';
import DoubleCardWrapper from '../dashboardCommon/doubleCardWrapper/DoubleCardWrapper';
import { EsgMetrics } from '../dashboardCommon/esgMetrics/EsgMetrics';
import EvolutionCompany from '../dashboardCommon/evolution/Evolution';
import List from '../dashboardCommon/list/List';
import OtherMetrics from '../dashboardCommon/otherMetrics/OtherMetrics';
import ScopeEnvironmentImpactCompany from '../dashboardCommon/scopeEnviromentImpact/ScopeEnviromentImpact';
import { useTotalDashboard } from './hooks/useTotalDashboard';
import FrameworksModal from '../frameworksModal/FrameworksModal';
import './styles.scss';
import useFrameworksPlans from 'customHooks/api/useFrameworksPlans';
import { useFeatureFlags } from 'customHooks/useFeatureFlags';
import { useAmplitude } from 'customHooks/useAmplitude';
import { EVENTS } from 'constants/amplitude';
import KeepImproving from '../dashboardCommon/keepImproving/KeepImproving';

function DashboardTotal() {
  useAmplitude(EVENTS.PAGE_VIEW);
  const organizationFound = useSelectedOrganization();
  const { startDate, endDate } = useContext(FilterDatesContext);
  const flags = useFeatureFlags();
  const {
    loading,
    disabled,
    dataScopes,
    dataYearComparison,
    dataCategories,
    dataHolding,
    totalCo2
  } = useTotalDashboard();
  const freeTrialPlan = checkFreePlan(organizationFound);
  const xOrganizationId = sessionStorage.getItem('x-organization-id');
  const { frameworkPlans } = useFrameworksPlans(xOrganizationId || '');
  const [frameworkModalOpen, setFrameworkModalOpen] = useState(false);

  useEffect(() => {
    const savedData = localStorage.getItem(`seenFrameworksSelectorModal-${xOrganizationId}`);
    let hasNotSeenModal = true; // Default to true if no valid data is found

    if (savedData) {
      try {
        const parsedData = JSON.parse(savedData);
        hasNotSeenModal = !parsedData.seen; // Modal should open if "seen" is not true
      } catch (error) {
        console.error('Error parsing localStorage data:', error);
      }
    }
    // Open the modal if frameworkPlans exist AND the modal has not been "seen"
    if (frameworkPlans?.length && hasNotSeenModal) {
      setFrameworkModalOpen(true);
    } else {
      setFrameworkModalOpen(false); // Ensure modal is closed otherwise
    }
  }, [frameworkPlans, xOrganizationId]);
  const saveFrameworksHandler = () => {
    const dataToSave = {
      seen: true
    };
    // Save with organization-specific key
    localStorage.setItem(
      `seenFrameworksSelectorModal-${xOrganizationId}`,
      JSON.stringify(dataToSave)
    );
    setFrameworkModalOpen(false); // Close the modal
  };
  const foundCsrdPurchasedPlan = frameworkPlans?.find(
    (framework) => framework.framework.name === 'csrd' && framework.purchased
  );

  if (!organizationFound) {
    return null;
  }
  if (frameworkModalOpen && flags?.sotDcycleDemos) {
    return (
      <FrameworksModal
        saveFrameworksHandler={saveFrameworksHandler}
        modalOpen={frameworkModalOpen}
      />
    );
  }
  return (
    <>
      <div className='dashboard__body-holdings'>
        <CompanyEnvironmentImpact
          data={dataScopes}
          organization={organizationFound}
          startDate={convertStringToDate(startDate)}
          endDate={convertStringToDate(endDate)}
          loading={loading}
          disabled={disabled}
        />
        {dataYearComparison ? (
          <EvolutionCompany
            data={dataYearComparison}
            dataEvolution={[]}
            startDate={convertStringToDate(startDate)}
            endDate={convertStringToDate(endDate)}
            loading={loading}
            disabled={disabled || freeTrialPlan}
            freeTrial={freeTrialPlan}
          />
        ) : (
          <LoaderCard />
        )}
        {dataCategories ? (
          <ScopeEnvironmentImpactCompany
            data={dataCategories}
            loading={loading}
            disabled={disabled || freeTrialPlan}
            freeTrial={freeTrialPlan}
          />
        ) : (
          <LoaderCard />
        )}
        <DoubleCardWrapper>
          <OtherMetrics
            organization={organizationFound}
            startDate={convertStringToDate(startDate)}
            endDate={convertStringToDate(endDate)}
            loading={loading}
            totalCo2={totalCo2}
          />
          {foundCsrdPurchasedPlan ? <EsgMetrics /> : <KeepImproving />}
        </DoubleCardWrapper>

        {dataHolding ? (
          <List
            data={dataHolding}
            loading={loading}
            disabled={disabled}
            freeTrial={freeTrialPlan}
          />
        ) : (
          <LoaderCard style={{ gridColumn: '1 / -1' }} />
        )}
      </div>
    </>
  );
}

export default DashboardTotal;
