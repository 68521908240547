import { useQuery } from '@tanstack/react-query';
import apiFetch from 'utils/apiFetch';
import { AxiosError } from 'axios';

type Stakeholder = 'suppliers' | 'b2b_clients' | 'final_users' | 'own_workforce' | 'investors';

interface SurveyParams {
  year: number;
  stakeholder: Stakeholder;
  organizationId: string;
  partner?: 'dcycle' | 'onau';
}

interface SurveyResponse {
  id: string;
  measurement_campaign_id: string;
  stakeholder: Stakeholder;
  survey_link_en: string;
  survey_link_es: string;
}

const fetchSurvey = async ({
  year,
  stakeholder,
  organizationId,
  partner = 'dcycle'
}: SurveyParams) => {
  const response = await apiFetch(
    'GET',
    `/v1/survey?year=${year}&stakeholder=${stakeholder}`,
    undefined,
    {
      'x-organization-id': organizationId,
      'x-partner': partner
    }
  );
  return response.data as SurveyResponse;
};

export const useSurvey = (params: SurveyParams) => {
  const { data, isLoading, error } = useQuery({
    queryKey: ['survey', params.year, params.stakeholder, params.organizationId],
    queryFn: () => fetchSurvey(params),
    retry: (_, error: AxiosError) => error?.response?.status !== 404
  });

  return { data, isLoading, error };
};
