import InfiniteList from 'components/ui/infiniteList/InfiniteListV2';
import { useColumns } from './hooks/useColumns';
import { useObtainSuppliers } from './hooks/useSuppliers';
import { useContext, useState } from 'react';
import { FilterDatesContext } from 'context/filterDatesContext';
import InputWrapper from 'components/ui/formComponents2/inputUtils/inputWrapper/InputWrapper';
import InputText from 'components/ui/formComponents2/inputText/InputText';
import { useTranslation } from 'react-i18next';
import Icon from 'components/ui/icon/Icon';
import Button from 'components/ui/button/Button';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from 'constants/routes';
import { Link } from 'react-router-dom';
import useDebounceValue from 'customHooks/useDebounceValue';
import { SurveyBanner } from '../SurveyBanner';

export const DMSuppliers = () => {
  const { startDate } = useContext(FilterDatesContext);
  const [searchValue, setSearchValue] = useState('');
  const debounceSearchValue = useDebounceValue(searchValue);
  const { t: t_esg } = useTranslation('', { keyPrefix: 'esgMetrics' });
  const { t: t_dm } = useTranslation('', { keyPrefix: 'doubleMateriality' });
  const columns = useColumns();
  const navigate = useNavigate();

  const year = startDate.split('/')[2];
  const { data, fetchNextPage, isLoading } = useObtainSuppliers({
    year: (Number(year) - 1).toString(),
    company_name: debounceSearchValue
  });
  const { suppliers, total } = data ?? {};

  return (
    <div
      style={{
        display: 'grid',
        gridTemplateRows: 'auto 55vh'
      }}>
      <SurveyBanner
        title={t_dm('supplierTitle')}
        subtitle={t_dm('supplierSubtitle')}
        stakeholder='suppliers'
        Cta1={
          <Button
            lookAndFeel={'secondary'}
            text={t_dm('context.suppliers.uploadPurchases')}
            size='small'
            onClick={() => navigate(ROUTES.MEASURE_ACTIVITY_PURCHASES)}
          />
        }
      />
      <InfiniteList
        fetchData={fetchNextPage}
        data={suppliers ?? []}
        columns={columns}
        loading={isLoading}
        firstLoading={isLoading}
        total={total}
        header={
          <div className='flex justify-between items-center w-full'>
            <div className='flex items-center gap-2'>
              <InputWrapper icon={'/images/icons/search.svg'}>
                <InputText
                  placeholder={t_esg('filters.search')}
                  value={searchValue}
                  onChange={(e) => setSearchValue(e.target.value)}
                />
              </InputWrapper>
              <div className='flex gap-2 align-center p-1 rounded-4 app-bg-color'>
                <Icon icon='multi_star' color={'blocked'} />
                <div className='secondary-text-color font-12'>
                  <span>{t_dm('context.suppliers.listDescription')}</span>{' '}
                  <Link to={ROUTES.MEASURE_ACTIVITY_PURCHASES} className='underline cursor-pointer'>
                    {t_dm('context.suppliers.listDescriptionLink')}
                  </Link>
                </div>
              </div>
            </div>
          </div>
        }
      />
    </div>
  );
};
