import { useInfiniteQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { setNotification } from 'actions/notification';
import { adaptFinalUserFrontToBack, adaptFinalUsersBackToFront } from 'adapters/finalUsers';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import {
  createFinalUser,
  deleteFinalUser,
  fetchDMFinalUsers,
  updateFinalUser
} from 'services/api/sotOrganizationData';

export const useObtainFinalUsers = ({ year }: { year: string }) => {
  const { data, fetchNextPage, isLoading } = useInfiniteQuery({
    queryKey: ['finalUsers', year],
    queryFn: fetchDMFinalUsers,
    initialPageParam: 1,
    select: adaptFinalUsersBackToFront,
    getNextPageParam: (lastPage) => {
      if (lastPage.page * lastPage.size >= lastPage.total) {
        return undefined;
      }

      return lastPage.page + 1;
    }
  });

  return { data, fetchNextPage, isLoading };
};

export const useCreateOrUpdateFinalUser = ({ year }: { year: string }) => {
  const { t } = useTranslation('', { keyPrefix: 'notification' });
  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  const { mutate } = useMutation({
    mutationFn: (finalUser: DMFinalUser) => {
      const backendFinalUser = adaptFinalUserFrontToBack(finalUser);
      return finalUser.id
        ? updateFinalUser(finalUser.id, backendFinalUser, year)
        : createFinalUser(backendFinalUser, year);
    },
    onSuccess: async () => {
      queryClient.invalidateQueries({ queryKey: ['finalUsers'] });
      dispatch(setNotification(t('edit')));
    },
    onError: () => {
      dispatch(setNotification(t('error')));
    }
  });

  return { createOrUpdateFinalUser: mutate };
};

export const useDeleteFinalUser = () => {
  const { t } = useTranslation('', { keyPrefix: 'notification' });
  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  const { mutate } = useMutation({
    mutationFn: (finalUserId: string) => {
      return deleteFinalUser(finalUserId);
    },
    onSuccess: async () => {
      queryClient.invalidateQueries({ queryKey: ['finalUsers'] });
      dispatch(setNotification(t('delete')));
    },
    onError: () => {
      dispatch(setNotification(t('error')));
    }
  });

  return { deleteFinalUser: mutate };
};
