import { switchGrouping } from 'actions/auth';
import InputWrapper from 'components/ui/formComponents2/inputUtils/inputWrapper/InputWrapper';
import Select from 'components/ui/formComponents2/select/Select';
import { UserContext } from 'context/userContext';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

type Props = {
  show: boolean;
};

const GroupingFilter = ({ show }: Props) => {
  const { t } = useTranslation('translation', { keyPrefix: 'dashboard' });
  const user = useContext(UserContext);
  const dispatch = useDispatch();

  const [options, setOptions] = useState<SelectOptionFormat[]>([]);
  const [grouping, setGrouping] = useState<SelectOptionFormat>({
    id: '',
    name: ''
  });

  useEffect(() => {
    if (!user?.groupings || user.groupings.length === 0) {
      setOptions([]);
      setGrouping({ id: '', name: '' });
      return;
    }

    setOptions(user.groupings.map((grouping) => ({ id: grouping.id, name: grouping.name })));

    if (user?.selectedGrouping) {
      const foundGrouping = user.groupings.find(
        (grouping) => grouping.id === user.selectedGrouping
      );
      if (foundGrouping) {
        setGrouping({
          id: foundGrouping.id,
          name: foundGrouping.name
        });
      }
      return;
    }

    setGrouping({
      id: user.groupings[0].id,
      name: user.groupings[0].name
    });
  }, [JSON.stringify(user?.groupings), JSON.stringify(user?.selectedGrouping)]);

  const onChange = (value: SelectOptionFormat<string>) => {
    setGrouping(value);
    dispatch(switchGrouping(value.id));
  };

  if (!show) return null;

  return (
    <InputWrapper label={t('groupingFilterLabel')} iconV2='folder'>
      <Select options={options} onChange={onChange} value={grouping} sort={false} />
    </InputWrapper>
  );
};

export default GroupingFilter;
