import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { switchGrouping, switchOrganization } from '../../../../actions/auth';
import { ROUTES } from '../../../../constants/routes';
import { UserContext } from '../../../../context/userContext';
import { checkMembership } from '../../../../services/api/membership';
import { InputSize } from '../../../../types/utilsEnums/input';
import Button from '../../../ui/button/Button';
import InputWrapper from '../../../ui/formComponents2/inputUtils/inputWrapper/InputWrapper';
import SelectWithExtraOptions from '../../../ui/formComponents2/select/Select';
import './styles.scss';
import useSelectedOrganization from 'customHooks/useSelectedOrganization';

const SwitchOrganization = () => {
  const user = useContext(UserContext);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const selectedOrg = useSelectedOrganization();

  const [options, setOptions] = useState<SelectOptionFormat[]>([]);
  const [itemSelected, setItemSelected] = useState<SelectOptionFormat>({
    id: '',
    name: ''
  });

  const handleOnChangeValue = async (value: SelectOptionFormat) => {
    if (!user) return;

    const membership = await checkMembership(user?.id, value.id);
    if (!membership) return;

    await dispatch(switchOrganization(value.id));
    navigate(ROUTES.IMPROVE_DASHBOARD_TOTAL);
    window.location.reload();
  };

  useEffect(() => {
    if (!user?.organizations) return;
    setOptions(
      user.organizations.map((org) => ({
        id: org.id,
        name: org.company_name
      }))
    );

    if (selectedOrg) {
      setItemSelected({
        id: selectedOrg.id,
        name: selectedOrg.company_name
      });
    }
  }, [JSON.stringify(user?.organizations)]);

  const navigateToCreateOrg = () => {
    navigate(`${ROUTES.REGISTER_COMPANY}/${user?.id}`);
  };

  return (
    <div className='header-organizations'>
      <InputWrapper iconV2={'organization'}>
        <SelectWithExtraOptions
          placeholder={t('header.selectOrganization')}
          options={options}
          value={itemSelected}
          onChange={handleOnChangeValue}
          extraOptions={
            <div className='mt-1'>
              <Button
                lookAndFeel='primary'
                text={t('header.create')}
                onClick={navigateToCreateOrg}
                size='small'
              />
            </div>
          }
          size={InputSize.MEDIUM}
        />
      </InputWrapper>
    </div>
  );
};

export default SwitchOrganization;
