import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import reactStringReplace from 'react-string-replace';
import { NotificationContext } from 'context/notificationContext';
import { UserContext } from 'context/userContext';
import { useFeatureFlags } from 'customHooks/useFeatureFlags';
import { BasicEmployee, Employee } from 'types/entities/employee';
import { EmployeePeriod } from 'types/entities/employeePeriod';
import { FilterSection } from 'components/layout/NewFilters/FilterSection';
import { FilterText } from 'components/layout/NewFilters/FilterText';
import { Filters } from 'components/layout/NewFilters/Filters';
import FilterHandlers from 'components/layout/NewFilters/FiltersHandlers';
import Breadcrumb from 'components/layout/breadcrumb/Breadcrumb';
import Button from 'components/ui/button/Button';
import CardCTA from 'components/ui/cards/cardCTA/CardCTA';
import FormHeader from 'components/ui/formComponents/formHeader/FormHeader';
import InfiniteList from 'components/ui/infiniteList/InfiniteListV2';
import Modal from 'components/ui/modal/Modal';
import TotalLegend from 'components/ui/totalLegend/TotalLegend';
import AddEmployee from './AddEmployee';
import DeleteEmployee from './DeleteEmployee';
import EditEmployee from './EditEmployee';
import useAdapterEmployees from './adapters/getEmployeesTableAdapter';
import ConfirmResend from './confirmResend/ConfirmResend';
import { ModalResendFormTable } from './confirmResend/ModalResendFormTable';
import { EmailFormModalEmployees } from './emailFormModalEmployees/EmailFormModalEmployees';
import useColumns from './hooks/useColumns';
import { useGetEmployees } from './hooks/useGetEmployees';
import StatusTagEmployee from './infoTag/StatusTagEmployee';
import AddPeriod from './periods/AddPeriod';
import DeletePeriod from './periods/DeletePeriod';
import EditPeriod from './periods/EditPeriod';
import QRCodeModal from './qrCodeModal/QRCodeModal';
import './styles.scss';
import TooltipWrapper from 'components/ui/tooltip/TooltipWrapper';
import DownloadCategoryModal from 'components/downloadCategoryModal/DownloadCategoryModal';
import Icon from 'components/ui/icon/Icon';
import { DefaultRecordType, ExpandableConfig } from 'rc-table/lib/interface';
import { DEFAULT_SORTERS_EMPLOYEES, EMPLOYEE_MODALS, type EmployeeModals } from './constants';
import { useSideBarFilters } from './hooks/useSideBarFilters';
import { findEmployeeData } from './utils/utils';

type ExpandType = {
  expand: boolean;
  id: string;
};

type IModalManager = {
  id?: EmployeeModals;
  payload?: string;
};

function Employees() {
  const { t } = useTranslation();
  const flags = useFeatureFlags();
  const user = useContext(UserContext);
  const setNotification = useContext(NotificationContext);
  const dispatch = useDispatch();
  const { adaptEmployeeListFromBack2Table } = useAdapterEmployees();

  const foundOrganization = user?.organizations?.find(
    (org) => org.id === user.selectedOrganization
  );

  const [openModal, setOpenModal] = useState<IModalManager>({ id: undefined, payload: undefined });
  const [open, setOpen] = useState(false);
  const [expand, setExpand] = useState<ExpandType>({ expand: false, id: '' });
  const [filters, setFilters] = useState<IFilter[]>([]);

  const [selectAll, setSelectAll] = useState(false);

  const {
    data: employees,
    fetchData,
    setData: setEmployees,
    loading,
    firstLoading,
    total,
    setTotal,
    removeElement,
    addElement,
    addElements,
    total2: totalPending,
    totalTerminated,
    totalFiltered,
    totalWithEmailFiltered
  } = useGetEmployees({
    filters,
    sorters: DEFAULT_SORTERS_EMPLOYEES
  });

  const { sideBarFilters } = useSideBarFilters();

  const onCloseModal = () => {
    setEmployeesSelected([]);
    setOpenModal({ id: undefined, payload: undefined });
  };

  const employeesWithEmail = employees.filter((employee) => Boolean(employee.email));

  const isSurpassingEmployeeLimit = Boolean(
    foundOrganization && total - totalTerminated >= foundOrganization.limit_employees
  );

  const handleShowAddEmployeeModal = () => setOpenModal({ id: EMPLOYEE_MODALS.ADD_EMPLOYEE });
  const handleShowSendCustomFormModal = () => setOpenModal({ id: EMPLOYEE_MODALS.SEND_CUSTOM });

  const handleShowQRModal = () =>
    isSurpassingEmployeeLimit
      ? setOpenModal({ id: undefined, payload: undefined })
      : setOpenModal({ id: EMPLOYEE_MODALS.QR_CODE });

  const handleOpenConfirmResendPending = () => {
    if (!selectAll && !employeesSelected.length) {
      setOpenModal({ id: EMPLOYEE_MODALS.SEND_PENDING });

      return;
    }

    setOpenModal({ id: EMPLOYEE_MODALS.CONFIRM_RESEND });
  };

  /* const handleOpenConfirmResend = () => {
    setShowConfirmResend(true);
  }; */

  const parseDescription = () => {
    if (!flags?.qrEmployees) return t('employees.startDescriptionOld');

    return reactStringReplace(t('employees.startDescription'), '{{qr}}', () => (
      <button className='highlight-text-color pointer' onClick={handleShowQRModal}>
        {t('employees.qr')}
      </button>
    ));
  };

  const addEmployees = (values: Employee[]) => {
    addElements(values);
    setTotal(employees.length);
    onCloseModal();
  };

  const addEmployee = async (employee: Employee) => {
    addElement(employee);
    onCloseModal();
  };

  const editEmployee = async (id: string, employee: BasicEmployee) => {
    setEmployees((prev) => {
      return prev.map((oldEmployee) => {
        if (oldEmployee.id !== id) return oldEmployee;
        return {
          ...oldEmployee,
          id,
          email: employee.email,
          name: employee.name
        };
      });
    });
    onCloseModal();
  };

  const deleteEmployee = (id: string) => {
    removeElement(id);
    dispatch(setNotification(t('notification.deleteEmployee')));
    onCloseModal();
  };

  const setPeriods = async (id: string, periods: EmployeePeriod[]) => {
    setEmployees((employees) => {
      const employeeFound = employees.find((employee) => employee.id === id);
      if (!employeeFound) return employees;

      const newEmployee: Employee = {
        ...employeeFound,
        periods
      };

      return employees.map((employee) => {
        if (employee.id === newEmployee.id) return newEmployee;
        return employee;
      });
    });

    onCloseModal();
  };

  const deletePeriod = async (employeeId: string, id: string) => {
    setEmployees((employees) => {
      const employeeFound = employees.find((employee) => employee.id === employeeId);
      if (!employeeFound) return employees;

      const newEmployee: Employee = {
        ...employeeFound,
        periods: employeeFound.periods && employeeFound.periods.filter((period) => period.id !== id)
      };

      return employees.map((employee) => (employee.id === newEmployee.id ? newEmployee : employee));
    });

    onCloseModal();
  };

  const expandableConfig: ExpandableConfig<DefaultRecordType> = {
    expandRowByClick: true,
    onExpand: (props, record) => {
      record.employeeId && setExpand({ expand: props, id: record.employeeId ?? '' });
    }
  };

  const { columns, employeesSelected, setEmployeesSelected, employeesUnselected } = useColumns({
    expand,
    employees,
    employeesWithEmail: employeesWithEmail.length,
    setOpenModal,
    selectAll,
    setSelectAll
  });

  const adaptedData = adaptEmployeeListFromBack2Table(employees);

  const getClassName = (record: DefaultRecordType) => {
    if (
      record?.status === 'terminated' ||
      record?.status === 'inactive' ||
      record?.status === 'loading'
    )
      return 'disabled';
    return '';
  };

  const { employee, period } = findEmployeeData(employees, openModal.payload, openModal.id);

  const resendToPending = employees.length === 0 || (!selectAll && !employeesSelected.length);

  return (
    <>
      <section className='employees'>
        <div className='employees__header page-header'>
          <h3 className='headline3-font on-light-text-color'>{t('employees.title')}</h3>
          <Breadcrumb />
        </div>
        <CardCTA>
          <CardCTA.Header>
            <span className='headline4-font'> {t('employees.start')}</span>
            <span className='subtitle3-font'>{parseDescription()}</span>
          </CardCTA.Header>
          <CardCTA.Buttons>
            <Button
              lookAndFeel={isSurpassingEmployeeLimit ? 'blocked' : 'primary'}
              text={t('employees.viewForm')}
              size='small'
              onClick={handleShowSendCustomFormModal}
            />
            <Button
              lookAndFeel={isSurpassingEmployeeLimit ? 'blocked' : 'secondary'}
              text={t('employees.addManual')}
              size='small'
              onClick={handleShowAddEmployeeModal}
            />
          </CardCTA.Buttons>
        </CardCTA>
        <Filters.Root setFilters={setFilters} filters={filters} setOpen={setOpen} open={open}>
          <InfiniteList
            data={adaptedData}
            fetchData={fetchData}
            columns={columns}
            loading={loading}
            firstLoading={firstLoading}
            total={total}
            rowClassName={(record) => getClassName(record)}
            header={
              <>
                <div className='flex gap-x-2 items-center'>
                  <FilterText field='search' type='il' placeholder={t('employees.inputFilter')} />
                  <Filters.Menu>
                    {sideBarFilters?.map((section) => (
                      <FilterSection.Multiple
                        key={`section-${section.title}`}
                        title={section.title}
                        field={section.field}
                        type='in'
                        options={Object.values(section.options).map((elem) => ({
                          label:
                            section.field === 'situation_period' || section.field === 'status' ? (
                              <StatusTagEmployee
                                status={elem.id}
                                text={t(`employees.${elem.id}`)}></StatusTagEmployee>
                            ) : (
                              elem.name
                            ),
                          value: elem.id
                        }))}
                      />
                    ))}
                  </Filters.Menu>

                  <FilterHandlers blacklistedFilters={{ all: ['name'] }} />
                </div>

                <div className='email-actions'>
                  <TooltipWrapper text={t('general.downloadButtonEmailTooltip')}>
                    <Button
                      lookAndFeel={'secondary'}
                      iconNode={<Icon icon='download' color='gray-dark' />}
                      text={t('general.download')}
                      size='small'
                      height='small'
                      onClick={() => setOpenModal({ id: EMPLOYEE_MODALS.SHOW_DOWNLOAD })}
                    />
                  </TooltipWrapper>
                  <Button
                    onClick={handleOpenConfirmResendPending}
                    text={
                      resendToPending
                        ? t('employees.resendPending')
                        : t('employees.resendSelected') +
                          (selectAll
                            ? ` (${
                                totalWithEmailFiltered - employeesUnselected.length
                              }/${totalFiltered})`
                            : ` (${employeesSelected.length}/${totalFiltered})`)
                    }
                    height='small'
                    size='small'
                    lookAndFeel={'secondary'}
                  />
                  <TotalLegend
                    total={total}
                    total2={totalPending}
                    loading={loading}
                    i18key='employees'
                  />
                </div>
              </>
            }
            expandable={expandableConfig}
          />
        </Filters.Root>
        <Modal
          show={openModal.id === EMPLOYEE_MODALS.ADD_EMPLOYEE}
          onClose={onCloseModal}
          width='700px'
          maxWidth='700px'>
          <AddEmployee addEmployee={addEmployee} employees={employees} type='in_itinere' />
        </Modal>

        <Modal
          show={openModal.id === EMPLOYEE_MODALS.SEND_CUSTOM}
          onClose={onCloseModal}
          width='650px'
          maxWidth='650px'>
          <EmailFormModalEmployees addEmployees={addEmployees} close={onCloseModal} />
        </Modal>

        <Modal
          show={openModal.id === EMPLOYEE_MODALS.EDIT_EMPLOYEE}
          onClose={onCloseModal}
          width='500px'
          maxWidth='500px'>
          {employee && <EditEmployee employeeToEdit={employee} editEmployee={editEmployee} />}
        </Modal>

        <Modal
          show={openModal.id === EMPLOYEE_MODALS.DELETE_EMPLOYEE}
          onClose={onCloseModal}
          width='428px'
          maxWidth='428px'>
          {openModal.payload && (
            <DeleteEmployee
              deleteEmployee={deleteEmployee}
              employeeToDelete={openModal.payload}
              user={user}
            />
          )}
        </Modal>

        <Modal
          show={openModal.id === EMPLOYEE_MODALS.ADD_PERIOD}
          onClose={onCloseModal}
          width='700px'
          maxWidth='700px'>
          {openModal.payload && (
            <AddPeriod setPeriods={setPeriods} employeeId={openModal.payload} />
          )}
        </Modal>

        <Modal
          show={openModal.id === EMPLOYEE_MODALS.PERIOD_TO_EDIT}
          onClose={onCloseModal}
          width='700px'
          maxWidth='700px'>
          {employee && period && (
            <EditPeriod
              setPeriods={setPeriods}
              employeeId={employee.id ?? ''}
              periodToEdit={period}
              type={period.commuting_type}
            />
          )}
        </Modal>
        <Modal
          show={openModal.id === EMPLOYEE_MODALS.PERIOD_TO_DELETE}
          onClose={onCloseModal}
          width='428px'
          maxWidth='428px'>
          {employee && openModal.payload && (
            <DeletePeriod
              deletePeriod={deletePeriod}
              employeeId={employee.id ?? ''}
              periodId={openModal.payload}
            />
          )}
        </Modal>

        <Modal
          show={
            openModal.id === EMPLOYEE_MODALS.SEND_PENDING ||
            openModal.id === EMPLOYEE_MODALS.CONFIRM_RESEND
          }
          onClose={onCloseModal}
          width='428px'
          maxWidth='428px'>
          <ConfirmResend
            onConfirm={onCloseModal}
            title={t('employees.confirmResend').replace(
              '{{employees}}',
              openModal.id === EMPLOYEE_MODALS.SEND_PENDING
                ? totalPending
                : selectAll
                ? totalWithEmailFiltered - employeesUnselected.length
                : employeesSelected.length
            )}
            sendToPending={openModal.id === EMPLOYEE_MODALS.SEND_PENDING}
            filters={filters}
            emailsToSend={
              openModal.id === EMPLOYEE_MODALS.SEND_PENDING
                ? [] // in this case we don't need to send the emails as we are sending to all the pending employees
                : employeesSelected.map((employee) => employee?.email ?? '')
            }
            exceptEmails={
              openModal.id === EMPLOYEE_MODALS.SEND_PENDING
                ? []
                : employeesUnselected.map((employee) => employee?.email ?? '')
            }
            sendToAll={openModal.id === EMPLOYEE_MODALS.SEND_PENDING ? false : selectAll}
          />
        </Modal>
        <Modal
          show={openModal.id === EMPLOYEE_MODALS.QR_CODE}
          onClose={onCloseModal}
          width='650px'
          maxWidth='650px'>
          <QRCodeModal />
        </Modal>
        <Modal
          show={openModal.id === EMPLOYEE_MODALS.RESEND_ONE}
          onClose={onCloseModal}
          width='600px'
          maxWidth='600px'>
          {employee?.email && (
            <ModalResendFormTable email={employee?.email} onConfirm={onCloseModal} />
          )}
        </Modal>
        <Modal
          show={openModal.id === EMPLOYEE_MODALS.SHOW_DOWNLOAD}
          onClose={onCloseModal}
          width='500px'>
          <DownloadCategoryModal category='employees' onClose={onCloseModal} />
        </Modal>
      </section>
    </>
  );
}

export default Employees;
