import FormCalendarDoubleSelect from 'components/ui/formComponents2/formInputs/formCalendarDoubleSelect/FormCalendarDoubleSelect';
import InputDateFilterSelect, {
  DateOptions
} from 'components/ui/formComponents2/inputDateFilterSelect/InputDateFilterSelect';
import { FilterDatesContext } from 'context/filterDatesContext';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { localStorageSetItem } from 'utils/localStorage';

type Props = {
  category: string;
};

const DateFilter = ({ category }: Props) => {
  const { t } = useTranslation('translation', { keyPrefix: 'dashboard' });
  const { t: tError } = useTranslation('translation', { keyPrefix: 'error' });

  const { startDate, endDate, setStartDate, setEndDate, setDateError, dateError } =
    useContext(FilterDatesContext);

  const handleSetStartDate = (value: string) => {
    if (dateError) return;
    setStartDate(value);
    setDateError(undefined);
    localStorageSetItem('start_date_dashboard', value);
  };

  const handleSetEndDate = (value: string) => {
    if (dateError) return;
    setEndDate(value);
    setDateError(undefined);
    localStorageSetItem('end_date_dashboard', value);
  };

  const handleDateError = () => {
    setDateError({
      error: 'date',
      description: tError('invalidDate')
    });
  };

  // if category is controlPanel, the date options should be years:
  if (category === 'controlPanel') {
    return (
      <FormCalendarDoubleSelect
        width={'370px'}
        label={t('dateFilterLabel')}
        startDate={startDate}
        endDate={endDate}
        handleChangeStartDate={handleSetStartDate}
        handleChangeEndDate={handleSetEndDate}
        handleEndDateError={handleDateError}
        handleStartDateError={handleDateError}
      />
    );
  }

  return (
    <InputDateFilterSelect
      setStartDate={handleSetStartDate}
      setEndDate={handleSetEndDate}
      startDate={startDate}
      endDate={endDate}
      label={t('dateFilterLabel')}
      options={[
        DateOptions.THIS_YEAR,
        DateOptions.LAST_YEAR,
        DateOptions.LAST_30_DAYS,
        DateOptions.LAST_3_MONTHS,
        DateOptions.CUSTOM
      ]}
      keepDatesOpen={true}
      handleDateError={handleDateError}
      dateError={dateError}
    />
  );
};

export default DateFilter;
