import { InfiniteData } from '@tanstack/react-query';

export const adaptInvestorsBackToFront = (
  backendInvestors: InfiniteData<BackendDMInvestorsResponse, number>
): { total: number; investors: DMInvestor[] } => ({
  total: backendInvestors.pages[0].total,
  investors: backendInvestors.pages.flatMap(({ items: investors }) =>
    investors.map((investor: BackendDMInvestor) => ({
      id: investor.id,
      name: investor.name,
      country: investor.country,
      highImportance: investor.relevance === 1
    }))
  )
});

export const adaptInvestorFrontToBack = (investor: DMInvestor): BackendDMInvestor => ({
  id: investor.id,
  name: investor.name,
  country: investor.country,
  relevance: investor.highImportance ? 1 : 0
});
