import Button from 'components/ui/button/Button';
import InputText from 'components/ui/formComponents2/inputText/InputText';
import InputWrapper from 'components/ui/formComponents2/inputUtils/inputWrapper/InputWrapper';
import { useTranslation } from 'react-i18next';
import { useObtainInvestors } from './hooks/useInvestors';
import { useContext, useState } from 'react';
import { FilterDatesContext } from 'context/filterDatesContext';
import { useColumns } from './hooks/useColumns';
import InfiniteList from 'components/ui/infiniteList/InfiniteListV2';
import Modal from 'components/ui/modal/Modal';
import { ModalCreateOrEditInvestor } from './ModalCreateOrEditInvestor';
import { ModalDeleteInvestor } from './ModalDeleteInvestor';
import { SurveyBanner } from '../SurveyBanner';

export const DMInvestors = () => {
  const { t: t_esg } = useTranslation('', { keyPrefix: 'esgMetrics' });
  const { t: t_dm } = useTranslation('', { keyPrefix: 'doubleMateriality' });

  const { startDate } = useContext(FilterDatesContext);
  const year = startDate.split('/')[2];

  const [investorToEdit, setInvestorToEdit] = useState<DMInvestor>();
  const [investorToDelete, setInvestorToDelete] = useState<DMInvestor>();
  const [searchValue, setSearchValue] = useState('');

  const columns = useColumns({
    setInvestorToEdit,
    setInvestorToDelete
  });
  const { data, fetchNextPage, isLoading } = useObtainInvestors({
    year
  });
  const { investors, total } = data ?? {};

  const filteredInvestors = investors?.filter((investor) =>
    investor.name.toLowerCase().includes(searchValue.toLowerCase())
  );

  return (
    <div
      style={{
        display: 'grid',
        gridTemplateRows: 'auto 55vh'
      }}>
      <SurveyBanner
        title={t_dm('investorsTitle')}
        subtitle={t_dm('investorsSubtitle')}
        Cta1={
          <Button
            lookAndFeel={'secondary'}
            text={t_dm('context.investors.addInvestors')}
            size='small'
            onClick={() =>
              setInvestorToEdit({
                name: '',
                country: '',
                highImportance: false
              })
            }
          />
        }
        stakeholder='investors'
      />
      <InfiniteList
        fetchData={fetchNextPage}
        data={filteredInvestors ?? []}
        columns={columns}
        loading={isLoading}
        firstLoading={isLoading}
        total={total}
        header={
          <div className='flex justify-between items-center w-full'>
            <div className='flex items-center gap-2'>
              <InputWrapper icon={'/images/icons/search.svg'}>
                <InputText
                  placeholder={t_esg('filters.search')}
                  value={searchValue}
                  onChange={(e) => setSearchValue(e.target.value)}
                />
              </InputWrapper>
            </div>
          </div>
        }
      />
      <Modal.WithPortal
        show={investorToEdit !== undefined}
        onClose={() => setInvestorToEdit(undefined)}>
        <ModalCreateOrEditInvestor
          investorData={investorToEdit}
          closeModal={() => setInvestorToEdit(undefined)}
        />
      </Modal.WithPortal>
      <Modal.WithPortal
        show={investorToDelete !== undefined}
        onClose={() => setInvestorToDelete(undefined)}>
        <ModalDeleteInvestor
          investorData={investorToDelete}
          closeModal={() => setInvestorToDelete(undefined)}
        />
      </Modal.WithPortal>
    </div>
  );
};
