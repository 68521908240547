import { useState } from 'react';
import InputCalendarDouble from '../inputCalendarDouble/InputCalendarDouble';
import InputWrapper from '../inputUtils/inputWrapper/InputWrapper';
import { ErrorDate } from '../inputCalendar/InputCalendar';
import SelectDatePredefined from '../inputCalendar/SelectDatePredefined';

export enum DateOptions {
  ALL = 'ALL',
  THIS_YEAR = 'THIS_YEAR',
  LAST_YEAR = 'LAST_YEAR',
  LAST_30_DAYS = 'LAST_30_DAYS',
  LAST_3_MONTHS = 'LAST_3_MONTHS',
  CUSTOM = 'CUSTOM'
}

type Props = {
  setStartDate: (value: string) => void;
  setEndDate: (value: string) => void;
  startDate: string;
  endDate: string;
  options?: DateOptions[];
  label?: string;
  keepDatesOpen?: boolean;
  handleDateError?: (value: ErrorDate) => void;
  dateError?: ErrorType;
};

const InputDateFilter = ({
  startDate,
  endDate,
  setStartDate,
  setEndDate,
  options,
  label,
  keepDatesOpen,
  handleDateError,
  dateError
}: Props) => {
  const [openCustom, setOpenCustom] = useState(false);

  const handleOnChangeStartDate = (value: string) => {
    setStartDate(value);
  };

  const handleOnChangeEndDate = (value: string) => {
    setEndDate(value);
  };

  return (
    <InputWrapper
      label={label}
      iconV2='calendar'
      className='input-date-filter'
      width={openCustom || keepDatesOpen ? '370px' : '228px'}
      error={dateError}>
      <SelectDatePredefined
        handleOnChangeStartDate={handleOnChangeStartDate}
        handleOnChangeEndDate={handleOnChangeEndDate}
        startDate={startDate}
        endDate={endDate}
        setOpenCustom={setOpenCustom}
        options={options}
      />
      {(openCustom || keepDatesOpen) && (
        <InputCalendarDouble
          startDate={startDate}
          endDate={endDate}
          handleChangeStartDate={handleOnChangeStartDate}
          handleChangeEndDate={handleOnChangeEndDate}
          disabledStartDate={false}
          disabledEndDate={false}
          maxEndDate={new Date(new Date().getFullYear(), 11, 31).toDateString()}
          handleEndDateError={handleDateError}
          handleStartDateError={handleDateError}
        />
      )}
    </InputWrapper>
  );
};

export default InputDateFilter;
